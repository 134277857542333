import { FunctionComponent } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { PaginatorWrapper } from 'components/PaginatorWrapper'
import { ContentfulArtistEdges, ContentfulPaginationData } from 'types'
import { fontLato, colours, mixins } from 'styles'

const ArtistWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2.5rem;
  padding-bottom: 2rem;
`
const ImageWrapper = styled.div`
  position: relative;
  background-color: ${colours.white};
  border: 1px solid ${colours.orange};
  ${mixins.hoverUnderline(colours.orange, '2px', '0.8rem', '20%')}
  h2 {
    ${fontLato}
    font-size: 2.6rem;
    font-weight: bold;
    color: ${colours.greyTwo};
    text-align: center;
    text-transform: uppercase;
    padding: 12px 5px;
  }
`

export interface AllArtistsProps {
  pageContext: ContentfulPaginationData
  data: {
    artists: ContentfulArtistEdges
  }
}

export const AllArtists: FunctionComponent<AllArtistsProps> = ({ pageContext, data }) => {
  const ids = pageContext.group.map(({ node }) => node.contentful_id)

  const artists = data.artists.edges
    .filter(({ node }) => ids.includes(node.contentful_id))
    .map(({ node }) => ({
      name: node.name,
      slug: node.slug,
      artistImage: node.artistImage.fluid,
    }))

  return (
    <PaginatorWrapper
      pageContext={pageContext}
      children={() => (
        <ArtistWrapper>
          {artists.map(({ name, slug, artistImage }) => (
            <Link key={slug} to={`/artists/${slug}`}>
              <ImageWrapper>
                <Img alt={name} fluid={artistImage} />
                <h2>{name}</h2>
              </ImageWrapper>
            </Link>
          ))}
        </ArtistWrapper>
      )}
    />
  )
}

export default AllArtists

export const query = graphql`
  query ArtistsQuery {
    artists: allContentfulArtist(sort: { order: ASC, fields: name }) {
      edges {
        node {
          contentful_id
          name
          slug
          artistImage {
            fluid(maxWidth: 640, sizes: "(max-width: 850px) 90vw, (max-width: 1149px) 460px, 320px") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

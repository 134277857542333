import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { Paginator } from 'components/Paginator'
import { mixins } from 'styles'
import { PaginationData } from 'types'

const Wrapper = styled.div`
  ${mixins.innerWrapperMixin}
  margin-top: 2rem;
`

export interface PaginatorWrapperProps {
  children: () => ReactNode
  pageContext: PaginationData
}

export const PaginatorWrapper: FunctionComponent<PaginatorWrapperProps> = ({ pageContext, children }) => {
  const usePaginator = pageContext.pageCount > 1
  return (
    <Wrapper>
      {usePaginator && <Paginator {...pageContext} prevText="<< Prev" nextText="Next >>" />}
      {children()}
      {usePaginator && <Paginator {...pageContext} prevText="<< Prev" nextText="Next >>" />}
    </Wrapper>
  )
}

export default PaginatorWrapper

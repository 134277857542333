import { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import classnames from 'classnames'
import { colours, constants } from 'styles'
import { PaginationData } from 'types'

interface NavLinkProps {
  url: string | null
  className?: string
}

const NavLink: FunctionComponent<NavLinkProps> = ({ url, children, className }) => {
  if (url) {
    return (
      <Link className={classnames('link', className)} to={url}>
        {children}
      </Link>
    )
  }
  return <span className={classnames('link', 'link-disabled', className)}>{children}</span>
}

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${constants.breakpoints.mobile} {
    justify-content: space-between;
    font-size: 2rem;
  }
  background-color: ${colours.black};
  color: ${colours.white};
  padding: 5px 10px;
  margin-bottom: 2rem;
  .link {
    margin: 0 30px;
  }
  .link-disabled {
    opacity: 0.5;
  }
  .page-no {
    min-width: 10px;
    text-align: center;
    &.link-disabled {
      opacity: 1;
      text-decoration: underline;
    }
    @media ${constants.breakpoints.mobile} {
      display: none;
    }
  }
`

function getPageLinkAddress(pageIndex: number, pathPrefix: string) {
  return pageIndex === 1 ? `/${pathPrefix}/` : `/${pathPrefix}/${pageIndex.toString()}`
}

export interface PaginatorProps extends PaginationData {
  prevText: string
  nextText: string
}

export const Paginator: FunctionComponent<PaginatorProps> = ({
  index,
  first,
  last,
  pageCount,
  pathPrefix,
  prevText,
  nextText,
}) => {
  const previousUrl = first ? null : getPageLinkAddress(index - 1, pathPrefix)
  const nextUrl = last ? null : getPageLinkAddress(index + 1, pathPrefix)

  return (
    <PaginationWrapper>
      <NavLink url={previousUrl}>{prevText}</NavLink>

      {[...Array(pageCount)].map((_, pageNo) => {
        const linkIndex = pageNo + 1
        const isSelected = index === linkIndex

        const pageUrl = isSelected ? null : getPageLinkAddress(linkIndex, pathPrefix)
        return (
          <NavLink key={linkIndex} url={pageUrl} className="page-no">
            {linkIndex}
          </NavLink>
        )
      })}
      <NavLink url={nextUrl}>{nextText}</NavLink>
    </PaginationWrapper>
  )
}
